import React from 'react';
import { connect } from 'react-redux';

import { SiteWrapper } from '../Dashboard/Layout.Styles';
import { Alert } from '../../../elements';
import { AppStateType } from '../../../store';
import { setAlert } from '../../../store/loadingsErrors/actions';
import Navbar from "../../Navbar";
import {useTheme} from "../../../theme/useTheme";
import Footer from "../../Footer";
import MainDecor from "./MainDecor";
import BurgerMenu from "../../BurgerMenu/BurgerMenu";
import MobileMenu from "../../MobileMenu/MobileMenu";

interface Props {
  alert?: any;
  children?: any;
  setAlert: (message: string | null, messageType: string | null) => void;
}

const AuthLayout: React.FC<Props> = (props: Props) => {
  const { alert, children, setAlert } = props;
  const { theme } = useTheme()

  return (
    <SiteWrapper className={`auth ${theme}`}>
      <Navbar/>
      <BurgerMenu />
      <MobileMenu />
      {children}
      <Alert alert={alert} pathname={children?.props?.location?.pathname} setAlert={setAlert} />
      <Footer/>
    </SiteWrapper>
  );
};

const mapState = (state: AppStateType) => {
  const { loadings } = state;
  return {
    alert: loadings.alert,
  };
};

export default connect(mapState, { setAlert })(AuthLayout);
