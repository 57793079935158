import styled from "styled-components";

export const LoadingLayoutStyles = styled.div<{bg?: string}>`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({bg}) => bg};
  
  .loading {
    &-logo {
      width: 196px;
      transform: translateX(20px);
    }
  }
`