import {useState} from "react";
import {SelectBody, SelectHeader, SelectWrapper} from "./Select.Styles";
import {Icon} from "../Icon/Icon";
import {Theme} from "../../theme/ThemeContext";
import {useTranslation} from "react-i18next";
import {useTheme} from "../../theme/useTheme";

interface Props {
  withTitle?: boolean,
  name: string,
  list: any[],
  onChange: (item: any) => void,
  defaultValue?: string,
  value?: {
    text: string,
    value: string | number
  }
}

export const Select = (props: Props) => {

  const {withTitle, list, onChange, name, defaultValue, value} = props
  const { t } = useTranslation()
  const { theme } = useTheme()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <SelectWrapper>

      <SelectHeader onClick={() => setIsOpen(prev => !prev)}>
        {withTitle
          ? <>
            <div className='selectHeader-title'>
              {name}:&nbsp;
            </div>
            <div className='selectHeader-text'>
              {t(value?.text || '')}
            </div>
          </>
          : (
            value?.text
              ?<div className='selectHeader-text' style={{marginRight: 'auto'}}>
                {t(value?.text || '')}
              </div>
              : <div className='selectHeader-text'  style={{marginRight: 'auto'}}>
                {defaultValue}
              </div>
          )
        }
        <Icon
          name={`${isOpen ? 'chevron-up': 'chevron-down'}`}
          size='10px'
          color={theme === Theme.DARK ? 'rgba(255, 255, 255, 0.4)' : 'rgba(22, 28, 47, 0.4)'}/>
      </SelectHeader>

      {
        isOpen
          ? <SelectBody>
            { list && list.length > 0 &&
              list.map((item) => {
                return (
                  <div
                    className='selectBody-item'
                    key={item.text}
                    onClick={() => {
                      onChange(item)
                      setIsOpen(false)
                    }}>
                    {t(item.text)}
                  </div>
                )
              })
            }
          </SelectBody>
          : null
      }


    </SelectWrapper>
  )
}