import styled from "styled-components";

export const Colored = styled.div`
  
  .colored {
    color: var(--secondary-color);

    &-negative {
      color: var(--light-red);
    }
    &-positive {
      color: var(--light-green)
    }
  }
  
`