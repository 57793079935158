import {Colored} from "./ColorText.Styles";

interface Props {
  value: number,
  percentages?: boolean,
  className?: string
}

export const ColorText = ({value, percentages, className}: Props) => {

  return (
    <Colored className={className}>
      <div className={`colored${value > 0 ? '-positive' : (value < 0 ? '-negative' : '')}`}>
        {value > 0 && '+'}
        {value < 0 && '-'}
        {Math.abs(value)}
        {percentages && '%'}
      </div>
    </Colored>
  )
}