import styled from 'styled-components';

export const LoaderStyles: any = styled.div<{color?: string}>`
  display: flex;
  justify-content: center;

  svg {
    width: 24px;
    height: 24px;
    stroke: ${({color}) => color};
  }
`;
