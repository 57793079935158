import {CheckBox} from "../../../elements/Checkbox/CheckBox";
import {Card} from "../../../elements/Card/Card";
import React from "react";
import {ColorText} from "../../../elements/ColorText/ColorText";
import {Account} from "../../../elements/Account/Account";
import {Link} from "react-router-dom";
import {PATHS} from "../../../const/paths.constants";
import CollapsibleCard from "../../../components/CollapsibleCard";
import {useTranslation} from "react-i18next";

export const TransactionTable = ({transactions}: {transactions: any[]}) => {

  const { t } = useTranslation();

  return (
    <CollapsibleCard
      title={`${t('home.table.title.latestTransactions')}`}
      body={
        <div className='flex-rows'>
          <div className='flex-cols checkboxes' style={{gridGap: '20px'}}>
            <CheckBox title={`${t('home.table.title.checkbox.ticktock')}`}/>
            <CheckBox title={`${t('home.table.title.checkbox.elector')}`}/>
          </div>
          <Card padding='16px 20px 29px'>
            {transactions.map((transaction) => {
              return (
                <React.Fragment key={transaction.info.id}>
                  <div className='flex-cols between'>
                    <div className='text-blue'>
                      {transaction.info.id}
                    </div>
                    <div className='secondaryText'>
                      {transaction.info.time}
                    </div>
                  </div>
                  <ColorText className='mainText middle-row' value={transaction.change}/>
                  <div className='flex-cols  between'>
                    <div className='secondaryText'>
                      {t('home.table.content.account')}
                    </div>
                    <Account account={transaction.account} size={28}/>
                  </div>
                  <div className='divider'/>
                </React.Fragment>
              )
            })}
            <Link to={PATHS.TRANSACTIONS} className='link'>
              {t('home.table.link.transactions')}
            </Link>
          </Card>
        </div>
      }
    />
  )
}