import {LoadingLayoutStyles} from "./LoadingLayout.Styles";
import {Theme} from "../../../theme/ThemeContext";
import React from "react";
import {useTheme} from "../../../theme/useTheme";
import {Loader} from "../../../elements";

export const LoadingLayout = () => {

  const { theme } = useTheme()

  return (
    <LoadingLayoutStyles bg={theme === Theme.DARK ? '#1B1C22' : '#F1F3F8'}>
      <div className='loading-logo'>
        <img src={theme === Theme.DARK ? '/img/main/textLogo.svg' : '/img/main/textLogoLight.svg'} alt='tbcc-logo'/>
      </div>
      <Loader/>
    </LoadingLayoutStyles>
  )
}