export const testMessagesData = [
  {
    hash: '165b...es84',
    route: {
      from: '0:a2s...s45',
      to: '0:a2d...v43'
    },
    value: 612.958484099
  },
  {
    hash: '0e8c...51sz',
    route: {
      from: '0:a2s...s45',
      to: '0:a2d...v43'
    },
    value: 35.099958436
  },
  {
    hash: '83s1...32xf',
    route: {
      from: '0:a2s...s45',
      to: '0:a2d...v43'
    },
    value: 12.251574215
  },
  {
    hash: '15sd...31as',
    route: {
      from: '0:a2s...s45',
      to: '0:a2d...v43'
    },
    value: 98.099958095
  },
  {
    hash: '192a...s1s0',
    route: {
      from: '0:a2s...s45',
      to: '0:a2d...v43'
    },
    value: 64.58484099
  },
  {
    hash: '83s1...32xf',
    route: {
      from: '0:a2s...s45',
      to: '0:a2d...v43'
    },
    value: 5024.251574215
  }
]

export const testTransactions = [
  {
    info: {
      id: '165b...es84',
      time: '10:34:54'
    },
    account: '0:a2dfds65f46sv43',
    change: 1.240755483
  },
  {
    info: {
      id: '0e8c...51sz',
      time: '10:32:51'
    },
    account: '0:a2sf4s5d64f65s45',
    change: 0.56421
  },
  {
    info: {
      id: '83s1...32xf',
      time: '10:32:15'
    },
    account: '0:a3tfds464f6sz04',
    change: 0
  },
  {
    info: {
      id: '15sd...31as',
      time: '10:32:12'
    },
    account: '0:a2df4s5d4f6v43',
    change: 0
  },
  {
    info: {
      id: '192a...s1s0',
      time: '10:32:05'
    },
    account: '0:a2dfs5d64f65sv43',
    change: 0.120142149
  },
  {
    info: {
      id: '83s1...32xf',
      time: '10:32:01'
    },
    account: '0:a3t4f5sd64f65z04',
    change: 0.14543
  }
]

export const testBlocks = [
  {
    shard: '-1 : 80 : 26954354',
    height: '13:00:01',
    id: '0:a2d...v43',
    transactions: 4
  },
  {
    shard: '-1 : 80 : 26954354',
    height: '13:00:01',
    id: '0:a2d...v43',
    transactions: 2
  },
  {
    shard: '-1 : 80 : 26954354',
    height: '13:00:01',
    id: '0:a2d...v43',
    transactions: 6
  },
  {
    shard: '-1 : 80 : 26954354',
    height: '13:00:01',
    id: '0:a2d...v43',
    transactions: 9
  },
  {
    shard: '-1 : 80 : 26954354',
    height: '13:00:01',
    id: '0:a2d...v43',
    transactions: 12
  },
  {
    shard: '-1 : 80 : 26954354',
    height: '13:00:01',
    id: '0:a2d...v43',
    transactions: 11
  }
]