import styled from 'styled-components';

export const SiteWrapper: any = styled.div`
  height: 100%;
  min-height: 100vh;
  background: var(--bg-color);
  color: var(--primary-color);
  font-family: var(--inter);
  
  &.dark {
    --bg-color: #1B1C22;
    --bg-secondary-color: #141414;
    --bg-tetriaty-color: rgba(255, 255, 255, 0.1);

    --primary-color: #FFFFFF;
    --secondary-color: rgba(255, 255, 255, 0.4);
    --tetriary-color: rgba(255, 255, 255, 0.25);
    --footer-links-color: rgba(255, 255, 255, 0.6);
    
    --input-border-color: rgba(255, 255, 255, 0.15);
    --gradient-border-color: linear-gradient(180deg, rgba(255, 255, 255, 0.0875) -3.83%, rgba(255, 255, 255, 0) 110.36%) 1;
    
    --divider-color: rgba(217, 217, 217, 0.1);
    
  }

  &.light {
    --bg-color: #F1F3F8;
    --bg-secondary-color: #FFFFFF;
    --bg-tetriaty-color: rgba(22, 28, 47, 0.15);

    --primary-color: #161C2F;
    --secondary-color: rgba(22, 28, 47, 0.4);
    --tetriary-color: rgba(22, 28, 47, 0.25);
    --footer-links-color: rgba(22, 28, 47, 0.6);

    --input-border-color: rgba(22, 28, 47, 0.1);
    --gradient-border-color: linear-gradient(180deg, rgba(22, 28, 47, 0.05) -3.83%, rgba(22, 28, 47, 0) 110.36%) 1;

    --divider-color: rgba(22, 28, 47, 0.05);

  }
`;

export const MainContainer: any = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
`;

export const Content: any = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 48.1em) {
    width: 100%;
  }
`;

export const DecorImgBox = styled.div`
  .image {
    &-first {
      position: absolute;
      width: 1440px;
      height: 1130px;
      pointer-events: none;
      z-index: 0;
      top: 0;
      right: 0;
      background-image: url("/img/main/abstracktDekor.svg");
      background-size: contain;
    }

    &-second {
      position: absolute;
      width: 1440px;
      height: 1130px;
      pointer-events: none;
      z-index: 0;
      top: 1000px;
      left: 0;
      background-image: url("/img/main/abstracktDekor2.svg");
      background-size: contain;
    }
  }
  

`
