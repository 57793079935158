import React from "react";
import {MainInputSearchBlock, MainSearchField} from "./MainSearchInput.Styles";
import {useTranslation} from "react-i18next";
import {Icon} from "../Icon/Icon";

interface Props {
  id?: string;
  className?: string;
  name: string;
  type: string;
  placeholder?: string;
  value?: string;
  text?: string;
  onClick?: () => void;
  inNavbar?: boolean
}

export const MainSearchInput: React.FC<Props> = (props: Props) => {

  const { id, className, name, type, placeholder, value, text, onClick, inNavbar } = props;
  const { t } = useTranslation();

  return (
    <MainInputSearchBlock inNavbar={inNavbar}>
      <MainSearchField
        name={name}
        type={type}
        placeholder={placeholder}
        autoComplete="off"
        inNavbar={inNavbar}
      />
      <Icon
        name="search"
        size="24"
        color='var(--primary-color)'/>
    </MainInputSearchBlock>
  )
}