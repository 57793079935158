import styled from 'styled-components';
import {NavLink} from "react-router-dom";

export const MobileMenuComponent:any = styled('div')`
  font-family: var(--inter);
  color: var(--primary-color);
  font-size: 1.5rem;
  
  .is-drilldown {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 8px 3px;
    
  }
  
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 4rem;
    padding: 0 3px;
    padding-top: 8px;
    
    &-logo {
      display: block;
      width: 7rem;
    }
    &-themeSwitcher{
      width: 24px;
      height: 24px;
      cursor: pointer;
      margin-left: auto;
      margin-right: 20px;
    }
    
    &-btn {
      background: none;
      border: 0;
      outline: none;
      box-shadow: none;
      padding: 0;
      margin-right: 2px;
    }
  }
  
  .menu {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 3px;
    flex-grow: 1;

    .menu {
      
      &-item {
        margin-top: 10px;
      }
      
      &__line {
        width: 100%;
        height: 0;
        margin: 13px 0;
        border: 1px solid #EFEFEF;
      }
    }

    button {
      display: flex;
      align-items: center;
      background: transparent;
      border: 0;
      padding: 0;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #EF2216;
      margin-top: 4px;

      span {
        margin-left: 17px;
      }
    }
  }
  
  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 42px;

    &-name {

      &__wrap {

      }
    }
  }
`;

