import React, {ReactNode} from 'react'
import {CardBody} from "./Card.Styles";

interface Props {
  padding?: string,
  children: ReactNode,
  className?: string
}

export const Card: React.FC<Props> = (props: Props) => {

  const {children, padding, className} = props

  return (
    <CardBody className={className} style={{padding: padding}}>
      {children}
    </CardBody>
  )
}