import React from 'react';
import {FooterWrapper} from "./Footer.styles";
import {useTheme} from "../../theme/useTheme";
import {Theme} from "../../theme/ThemeContext";
import {Link} from "react-router-dom";
import {PATHS} from "../../const/paths.constants";
import {useTranslation} from "react-i18next";

const Footer = () => {

  const {theme} = useTheme()
  const { t } = useTranslation();

  return (
    <FooterWrapper className='footer'>
      <div className='footer-wrap'>
        <div className='footer-logo__wrap'>
          <Link to={PATHS.HOME} className='footer-logo__link'>
            <div className='footer-logo'>
              <img src={theme === Theme.DARK ? '/img/main/textLogoGrayDark.svg' : '/img/main/textLogoGrayLight.svg'} alt='tbcc-logo'/>
            </div>
          </Link>
          <div className='footer-copyright'>
            Copyright © 2022 TBCC. All rights reserved.
          </div>
        </div>
        <div className='footer-item__wrap'>
          <Link to={PATHS.TRANSACTIONS} className='footer-item'>
            {t('navBar.transactions')}
          </Link>
          <Link to={PATHS.TOKENS} className='footer-item'>
            {t('navBar.tokens')}
          </Link>
          <Link to={PATHS.BLOCKS} className='footer-item'>
            {t('navBar.blocks')}
          </Link>
          <Link to={PATHS.MESSAGES} className='footer-item'>
            {t('navBar.messages')}
          </Link>
          <Link to={PATHS.VALIDATORS} className='footer-item'>
            {t('navBar.validators')}
          </Link>
          <Link to={PATHS.CONTRACTS} className='footer-item'>
            {t('navBar.contracts')}
          </Link>
        </div>
      </div>
    </FooterWrapper>
  );
};

export default Footer;