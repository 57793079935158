import styled from 'styled-components';

export const ContainerBlock: any = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 20px 50px;
  max-width: 1440px;

  @media (max-width: 992px) {
    padding: 16px;
  }
`;
