import { BoxProps } from "@mui/material/Box";
import styled from "styled-components";

export const CardBody:any = styled.div<BoxProps>`
  width: 100%;
  height: auto;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.0875); //fallback border color
  border-image: var(--gradient-border-color);
  background: var(--bg-tetriaty-color);
`