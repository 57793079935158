import styled from "styled-components";

export const FooterWrapper = styled.div`
  width: 100%;
  height: 240px;
  display: flex;
  align-items: center;
  background: var(--bg-secondary-color);
  position: relative;
  z-index: 1;
  
  .footer {
    &-wrap {
      width: 100%;
      max-width: var(--container-max-width);
      margin: 0 auto;
      padding: 0 48px 0 53px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    
    &-copyright {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      color: var(--tetriary-color);
    }
    
    &-logo {
      width: 150px;
      margin-bottom: 10px;
    }
    
    &-item {
      font-weight: 500;
      font-size: 14px;
      color: var(--footer-links-color);
      margin-left: 47px;
      
      &__wrap{
        display: flex;
      }
    }
  }

  @media only screen and (max-width: 60.6em) {
    height: auto;
    .footer {
      
      &-wrap {
        flex-direction: column-reverse;
        justify-content: center;
        margin-top: 90px; 
        padding: 15px;
      }

      &-logo {
        margin-top: 30px;
        
        &__link {
          display: block;
          height: fit-content;
          width: fit-content;
        }
        
        &__wrap{
          flex-direction: column;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      &-copyright {
        text-align: center;
        font-size: 14px;
        margin-bottom: 50px;
      }

      &-item {
        text-align: center;
        margin-left: 0;
        margin-bottom: 50px;
        font-size: 16px;
        
        &__wrap{
          flex-direction: column;
        }
      }
    }
  }
`