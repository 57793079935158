import CssBaseline from '@mui/material/CssBaseline';
import { StylesProvider } from '@mui/styles';
import React, {lazy, Suspense} from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { GlobalStyle } from './App.Styles';
import { PATHS } from './const/paths.constants';
import GuestRoutes from './routes/guestRoutes';
import Home from './pages/Home/Home';
import PlaceHolder from "./pages/PlaceHolder/PlaceHolder";
import {LoadingLayout} from "./components/Layout/LoadingLayout/LoadingLayout";

const BlocksPage = lazy(() => import('./pages/Blocks'))
const TransactionsPage = lazy(() => import('./pages/Transactions'))
const MessagesPage = lazy(() => import('./pages/Messages'))
const TokensPage = lazy(() => import('./pages/Tokens'))
const AccountsPage = lazy(() => import('./pages/Accounts'))
const ValidatorsPage = lazy(() => import('./pages/Validators'))
const TokenTransactions = lazy(() => import('./pages/TokenTransactions'))
const NFTCollections = lazy(() => import('./pages/Collections'))

function App() {
  return (
    <StylesProvider injectFirst>
      <GlobalStyle />
      <CssBaseline />
      <Suspense fallback={<LoadingLayout/>}>
        <Routes>
          <Route
            path="*"
            element={<Navigate to={PATHS.HOME} replace />}
          />
          <Route
            path={PATHS.HOME}
            element={
              <GuestRoutes title="navBar.home">
                <Home />
              </GuestRoutes>
            }
          />
          <Route
            path={PATHS.BLOCKS}
            element={
              <GuestRoutes title="navBar.blocks">
                <BlocksPage/>
              </GuestRoutes>
            }
          />
          <Route
            path={PATHS.TRANSACTIONS}
            element={
              <GuestRoutes title="navBar.transactions">
                <TransactionsPage/>
              </GuestRoutes>
            }
          />
          <Route
            path={PATHS.MESSAGES}
            element={
              <GuestRoutes title="navBar.messages">
                <MessagesPage/>
              </GuestRoutes>
            }
          />
          <Route
            path={PATHS.ACCOUNTS}
            element={
              <GuestRoutes title="navBar.accounts">
                <AccountsPage/>
              </GuestRoutes>
            }
          />
          <Route
            path={PATHS.TOKENS}
            element={
              <GuestRoutes title="navBar.tokens">
                <TokensPage/>
              </GuestRoutes>
            }
          />
          <Route
            path={PATHS.VALIDATORS}
            element={
              <GuestRoutes title="navBar.validators">
                <ValidatorsPage/>
              </GuestRoutes>
            }
          />
          <Route
            path={PATHS.TOKEN_TRANSACTIONS}
            element={
              <GuestRoutes title="navBar.tokenTransactions">
                <TokenTransactions/>
              </GuestRoutes>
            }
          />
          <Route
            path={PATHS.COLLECTIONS}
            element={
              <GuestRoutes title="navBar.collections">
                <NFTCollections/>
              </GuestRoutes>
            }
          />
          <Route
            path={PATHS.CONTRACTS}
            element={
              <GuestRoutes title="navBar.contracts">
                <PlaceHolder name='Contracts'/>
              </GuestRoutes>
            }
          />
          <Route
            path={PATHS.NETWORK_CONFIG}
            element={
              <GuestRoutes title="navBar.networkConfig">
                <PlaceHolder name='Network Config'/>
              </GuestRoutes>
            }
          />
        </Routes>
      </Suspense>
    </StylesProvider>
  );
}

export default App;
