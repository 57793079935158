import styled from "styled-components";
import {FormControlInput} from "../Input/Input.Styles";
import {InputProps} from "@mui/material";

export const MainInputSearchBlock = styled.div<{inNavbar?: boolean}>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 54px;
  max-width: ${({inNavbar}) => inNavbar ? '1440px' : '850px'};
  border: ${({inNavbar}) => inNavbar ? 'none' : '1px solid var(--input-border-color)'};
  filter: drop-shadow(0px 12px 24px rgba(0, 0, 0, 0.15));
  margin: 0 auto;
  padding: ${({inNavbar}) => inNavbar ? '0 50px 0 33px' : '0 16px 0 0'};

  @media only screen and (max-width: 60.6em) {
    padding: ${({inNavbar}) => inNavbar ? '0 10px' : '0 16px 0 0'};
    background-color: ${({inNavbar}) => inNavbar ? 'var(--bg-tetriaty-color)' : 'transparent'};
    backdrop-filter: ${({inNavbar}) => inNavbar ? 'blur(18px)' : 'none'};
  }
`

export const MainSearchField = styled(FormControlInput)<{inNavbar?: boolean}>`
  background: transparent;
  box-shadow: none;
  border: none;
  color: var(--primary-color);
  font-size: ${({inNavbar}) => inNavbar ? '14px' : '16px'};
  font-weight: 400;
  
  &::placeholder {
    color: var(--secondary-color);
    font-size: ${({inNavbar}) => inNavbar ? '14px' : '16px'};
  }
`