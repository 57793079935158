import React from "react";
import {PATHS} from "../../const/paths.constants";
import { MobileMenuComponent } from './BurgerMenu.Styles';
import {Icon, LanguageSelector, Logo} from "../../elements";
import {Link, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {logout} from "../../store/user/actions";
import {AppStateType} from "../../store";
import {setMobileMenuOpen} from "../../store/app/actions";
import {Theme} from "../../theme/ThemeContext";
import {useTheme} from "../../theme/useTheme";
import {MainSearchInput} from "../../elements/MainSearchInput/MainSearchInput";

type BurgerMenuProps = {
  mobileMenuOpened: boolean;
  logout: () => void;
  setMobileMenuOpen: (value: boolean) => void;
};

const BurgerMenu: React.FC<BurgerMenuProps> = (props: BurgerMenuProps) => {
  const { mobileMenuOpened, logout, setMobileMenuOpen } = props;
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { theme, toggleTheme } = useTheme()

  return (
    <MobileMenuComponent className={`off-canvas position-right is-transition-push ${mobileMenuOpened ? 'is-open' : 'is-closed'}`}>
      <div className="header">
        <div className='header-themeSwitcher' onClick={toggleTheme}>
          <img src={theme === Theme.DARK ? '/img/main/switchLight.svg' : '/img/main/switchDark.svg'} alt='theme-switcher'/>
        </div>
        <button
          className="header-btn"
          onClick={() => setMobileMenuOpen(!mobileMenuOpened)}
        >
          <img src="/img/main/crest.svg" alt=""/>
        </button>
      </div>
      <div className="is-drilldown">
        <MainSearchInput
          name='search'
          type='text'
          placeholder={`${t('home.input.placeholder')}`}
          inNavbar
        />
        <ul className="menu">
            <Link to={PATHS.BLOCKS} className='menu-item' onClick={() => setMobileMenuOpen(!mobileMenuOpened)}>
              {t('navBar.blocks')}
            </Link>
            <Link to={PATHS.TRANSACTIONS} className='menu-item' onClick={() => setMobileMenuOpen(!mobileMenuOpened)}>
              {t('navBar.transactions')}
            </Link>
            <Link to={PATHS.MESSAGES} className='menu-item' onClick={() => setMobileMenuOpen(!mobileMenuOpened)}>
              {t('navBar.messages')}
            </Link>
            <Link to={PATHS.ACCOUNTS} className='menu-item' onClick={() => setMobileMenuOpen(!mobileMenuOpened)}>
              {t('navBar.accounts')}
            </Link>
            <Link to={PATHS.TOKENS} className='menu-item' onClick={() => setMobileMenuOpen(!mobileMenuOpened)}>
              {t('navBar.tokens')}
            </Link>
            <Link to={PATHS.VALIDATORS} className='menu-item' onClick={() => setMobileMenuOpen(!mobileMenuOpened)}>
              {t('navBar.validators')}
            </Link>
            <Link to={PATHS.CONTRACTS} className='menu-item' onClick={() => setMobileMenuOpen(!mobileMenuOpened)}>
              {t('navBar.contracts')}
            </Link>
            <Link to={PATHS.NETWORK_CONFIG} className='menu-item' onClick={() => setMobileMenuOpen(!mobileMenuOpened)}>
              {t('navBar.networkConfig')}
            </Link>
        </ul>

        {/* <div className="bottom"> */}
        {/*  <LanguageSelector /> */}
        {/* </div> */}

      </div>
    </MobileMenuComponent>
  );
};

const mapState = (state: AppStateType) => {
  const { app } = state;
  return {
    mobileMenuOpened: app.mobileMenuOpened
  };
};

export default connect(mapState, { logout, setMobileMenuOpen })(BurgerMenu);
