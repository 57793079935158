import styled from 'styled-components';

export const HomeContainer: any = styled.div`
  display: flex;
  flex-direction: column;
  font-family: var(--inter);

  .home {
    &-title {
      font-weight: 600;
      font-size: 48px;
      line-height: 86px;
      color: var(--primary-color);
      text-align: center;
      
      &_wrap {
        margin-top: 120px;
        margin-bottom: 14px;
      }
    }
    
    &-statistic {
      
      &_grid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 16px;
        margin-top: 66px;
      }
      
      &_item {
        width: 100%;
        height: 86px;
        backdrop-filter: blur(5px);
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 0 10px 0 18px;
        border: 1px solid rgba(255, 255, 255, 0.0875); //fallback border color
        border-image: var(--gradient-border-color);
        background: rgba(255, 255, 255, 0.1);
        
        &-title {
          font-size: 13px;
          font-weight: 500;
          color: var(--secondary-color);
        }
        
        &-value {
          font-size: 20px;
          font-weight: 600;
          color: var(--primary-color);
        }
      }
    }
    
    &-mainGrid {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 40px;
      margin-top: 63px;
      margin-bottom: 80px;
    }
    
    &-titleRow {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 15px;
      
      &_text {
        font-weight: 600;
        font-size: 28px;
        line-height: 56px;
        color: var(--primary-color)
      }
    }
    
    &-account {
      display: flex;
      align-items: center;
    }

    &-mobileTable {
      display: none;
    }
  }

  @media only screen and (max-width: 60.6em) { //968px

    .home {
      &-title {
        font-size: 32px;
        line-height: 48px;
        
        &_wrap {
          margin-top: 122px;
          margin-bottom: 19px;
        }
      }

      &-statistic {

        &_grid {
          grid-template-columns: 1fr;
          grid-gap: 14px;
          margin-top: 72px;
        }

        &_item {

          &-title {
          }

          &-value {
          }
        }
      }

      &-mainGrid {
        display: none;
      }

      &-titleRow {

        &_text {
        }
      }

      &-account {
      }

      &-mobileTable {
        display: block;
        margin-top: 67px;
      }
    }
  }
  
  .between {
    justify-content: space-between;
    align-items: center;
  }
  
  .text-blue {
    color: var(--blue);
    font-weight: 500;
    font-size: 16px;
  }

  .secondaryText {
    font-weight: 500;
    font-size: 12px;
    color: var(--secondary-color);
  }

  .mainText {
    font-weight: 500;
    font-size: 14px;
    color: var(--primary-color);
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: var(--divider-color);
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .middle-row {
    margin-top: 20px;
    margin-bottom: 18px;
  }
  
  .link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    font-weight: 500;
    font-size: 16px;
    color: var(--primary-color);
    backdrop-filter: blur(18px);
    background-color: var(--bg-tetriaty-color);
  }
  
  .arrow {
    color: var(--secondary-color)
  }
  
  .checkboxes {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
`;

export const InfoTable = styled.div`
  .table {
    width: 100%;
    
    &-mainText {
      font-weight: 500;
      font-size: 14px;
      color: var(--primary-color);
    }
    &-secondaryText {
      font-weight: 500;
      font-size: 12px;
      color: var(--secondary-color);
    }
    
    &_divider {
      width: 100%;
      height: 1px;
      background-color: var(--divider-color);
    }
    
    &-grid {
      width: 100%;
      height: 60px;
      align-content: center;
      display: grid;
      grid-template-columns: 0.7fr 1fr 1.5fr;
      
      :last-child {
        align-items: end;
      }
      
      &_title {
        font-weight: 500;
        font-size: 13px;
        color: var(--secondary-color);
      }
      
      &__item {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
    
    &-link {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 50px;
      font-weight: 500;
      font-size: 16px;
      color: var(--primary-color);
      backdrop-filter: blur(18px);
      background-color: var(--bg-tetriaty-color);
      margin-top: 29px;
    }
  }
  
  .last {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`