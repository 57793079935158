import styled from "styled-components";

export const AccountStyles = styled.div<{size?: number}>`
  display: flex;
  align-items: center;
  
  .image {
    width: ${({size}) => `${size}px`};
    height: ${({size}) => `${size}px`};
    margin-right: 8px;
  }
`