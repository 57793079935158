import {FC, useState} from "react";
import {DropdownSelector, PageSelector, PaginationWrapper} from "./Pagination.Styles";
import {useTranslation} from "react-i18next";
import {Icon} from "../Icon/Icon";
import {useTheme} from "../../theme/useTheme";
import {Theme} from "../../theme/ThemeContext";

interface Props {
  items: number,
  activePage: number,
  maxPage: number,
  setActivePage: (number: number) => void,
  setItems: (count: number) => void
}

export const Pagination: FC<Props> = (props) => {

  const { items, setItems, activePage, maxPage, setActivePage } = props

  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()
  const { theme } = useTheme()

  const handleChangeItems = (count: number) => {
    setActivePage(1)
    setItems(count)
    setIsOpen(false)
  }

  return (
    <PaginationWrapper>

      <DropdownSelector>
        <div className='dropdown-header' onClick={() => setIsOpen(prev => !prev)}>
          <div className='dropdown-header__text'>
            {t('tableFooter.items')}:&nbsp;
          </div>
          <div className='dropdown-header__number'>
            {items}
          </div>
          <Icon
            name={`${isOpen ? 'chevron-up': 'chevron-down'}`}
            size='10px'
            color={theme === Theme.DARK ? '#FFF' : '#161C2F'}/>
        </div>
        {
          isOpen
            ? <div className='dropdown-body'>
            <div className='dropdown-body__item' onClick={() => handleChangeItems(10)}>
              10
            </div>
              <div className='dropdown-body__item' onClick={() => handleChangeItems(20)}>
                20
              </div>
              <div className='dropdown-body__item' onClick={() => handleChangeItems(50)}>
                50
              </div>
            </div>
            : null
        }

      </DropdownSelector>

      <PageSelector>
        <div className='pagination-text'>
          {t('tableFooter.page')}&nbsp;{activePage}
        </div>
        <div
          className={`pagination-btn_first pagination-btn ${activePage === 1 ? 'pagination-btn--disabled' : 'pagination-btn--active'}`}
          onClick={() => {
            if (activePage > 1) {
              setActivePage(activePage - 1)
            }
          }}
        >
          <Icon
            name='chevron-left'
            size='12px'
            color={activePage === 1
              ? (theme === Theme.DARK ? 'rgba(255, 255, 255, 0.35)' : 'rgba(22, 28, 47, 0.35)')
              : ((theme === Theme.DARK ? '#FFF' : '#161C2F'))
          }/>
        </div>
        <div
          className={`pagination-btn ${activePage === maxPage ? 'pagination-btn--disabled' : 'pagination-btn--active'}`}
          onClick={() => {
            if (activePage < maxPage) {
              setActivePage(activePage + 1)
            }
          }}
        >
          <Icon
            name='chevron-right'
            size='12px'
            color={activePage === maxPage
              ? (theme === Theme.DARK ? 'rgba(255, 255, 255, 0.35)' : 'rgba(22, 28, 47, 0.35)')
              : ((theme === Theme.DARK ? '#FFF' : '#161C2F'))
            }/>
        </div>
      </PageSelector>

    </PaginationWrapper>
  )
}