import styled from "styled-components";

export const NavbarWrapper: any = styled.nav<{isTransparent?: boolean}>`
  width: 100%;
  margin: 0 auto;
  background: ${({isTransparent}) => isTransparent ? 'transparent' : 'var(--bg-tetriaty-color)'};
  transition: all 300ms ease;
  
  .navbar {
    &-wrap {
      width: 100%;
      max-width: var(--container-max-width);
      margin: 0 auto;
      display: flex;
      align-items: center;
      padding: 33px 47px 0 53px;
      color: var(--primary-color);
    }
    
    &-logo {
      width: 196px;
      transform: translateY(3px);
    }
    
    &-itemsWrapper {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
      margin-right: 20px;
    }
    
    &-item {
      font-weight: 500;
      font-size: 14px;
      margin-right: 35px;
    }
    
    &-theme-switcher {
      width: 16px;
      height: 16px;
      cursor: pointer;
      margin-right: 21px;
    }
    
    &-divider {
      width: 100%;
      height: 1px;
      background-color: var(--divider-color);
      max-width: 1340px;
      margin: 22px auto 5px;
    }
  }

  @media only screen and (max-width: 78.3em) { //1250px
    .navbar {
      &-logo {
        height: fit-content;
        width: 160px;
      }
      &-itemsWrapper {
        margin-right: 0px;
      }

      &-item {
        margin-right: 25px;
      }
    }
  }

  @media only screen and (max-width: 69.2em) { //1105px
    .navbar {
      &-item {
        margin-right: 10px;
      }

      &-wrap {
        padding: 33px 30px 0;
      }
    }
  }

  @media only screen and (max-width: 60.6em) { //968px
    display: none;
  }
`