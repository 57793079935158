import React from 'react';
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { AppStateType} from "../../store";

import { MobileMenuWrapper } from './MobileMenu.styles';
import {useTranslation} from "react-i18next";

import {Icon, LanguageSelector} from '../../elements';
import { PATHS } from "../../const/paths.constants";
import { setMobileMenuOpen } from "../../store/app/actions";
import {Theme} from "../../theme/ThemeContext";
import {useTheme} from "../../theme/useTheme";
import {MainSearchInput} from "../../elements/MainSearchInput/MainSearchInput";

type MobileMenuProps = {
  mobileMenuOpened: boolean;
  setMobileMenuOpen: (value: boolean) => void;
};

const MobileMenu: React.FC<MobileMenuProps> = (props: MobileMenuProps) => {
  const { mobileMenuOpened, setMobileMenuOpen } = props;
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { theme, toggleTheme } = useTheme()

  return (
    <MobileMenuWrapper className='mobileMenu'>
      <div className="mobileMenu-wrap">
        <Link to={PATHS.HOME}>
          <div className='mobileMenu-logo'>
            <img src={theme === Theme.DARK ? '/img/main/textLogo.svg' : '/img/main/textLogoLight.svg'} alt='tbcc-logo'/>
          </div>
        </Link>
        {/*<div className='mobileMenu-themeSwitcher' onClick={toggleTheme}>*/}
        {/*  <img src={theme === Theme.DARK ? '/img/main/switchLight.svg' : '/img/main/switchDark.svg'} alt='theme-switcher'/>*/}
        {/*</div>*/}
        <div className='mobileMenu-themeSwitcher'>
          <LanguageSelector/>
        </div>
        <div
          className={`mobileMenu-item ${mobileMenuOpened ? '-opened' : ''}`}
          onClick={() => setMobileMenuOpen(!mobileMenuOpened)}
        >
          <div className='mobileMenu-link'>
            <div
              className="mobileMenu-burger"
            >
              <div className="x" />
              <div className="y" />
              <div className="z" />
            </div>
          </div>
        </div>
      </div>
    </MobileMenuWrapper>
  );
};

const mapStateToProps = (state: AppStateType) => {
  const { app } = state;
  return {
    mobileMenuOpened: app.mobileMenuOpened,
  };
};

export default connect(mapStateToProps, { setMobileMenuOpen })(MobileMenu);
