import {useTranslation} from "react-i18next";
import {CheckBox} from "../../../elements/Checkbox/CheckBox";
import {Card} from "../../../elements/Card/Card";
import React from "react";
import {Account} from "../../../elements/Account/Account";
import {Link} from "react-router-dom";
import {PATHS} from "../../../const/paths.constants";
import CollapsibleCard from "../../../components/CollapsibleCard";

export const BlocksTable = ({blocks}: {blocks: any[]}) => {

  const { t } = useTranslation();

  return (
    <CollapsibleCard
      title={`${t('home.table.title.latestBlocks')}`}
      body={
        <div className='flex-rows'>
          <div className='flex-cols checkboxes' style={{gridGap: '20px'}}>
            <CheckBox title={`${t('home.table.title.checkbox.empty')}`}/>
          </div>
          <Card padding='16px 20px 29px'>
            {blocks.map((block) => {
              return (
                <React.Fragment key={block.shard}>
                  <div className='flex-cols between'>
                    <div className='text-blue'>
                      {block.shard}
                    </div>
                    <div className='secondaryText'>
                      {t('home.table.content.transfer')}
                    </div>
                  </div>
                  <div className='flex-cols between middle-row'>
                    <div className='secondaryText'>
                      {t('home.table.header.id')}
                    </div>
                    <div className='text-blue'>
                      {block.id}
                    </div>
                  </div>
                  <div className='flex-cols between'>
                    <div className='secondaryText'>
                      {t('home.table.header.transactions')}
                    </div>
                    <div className='mainText'>
                      {block.transactions}
                    </div>
                  </div>
                  <div className='divider'/>
                </React.Fragment>
              )
            })}
            <Link to={PATHS.BLOCKS} className='link'>
              {t('home.table.link.blocks')}
            </Link>
          </Card>
        </div>
      }
    />
  )
}