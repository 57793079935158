import React, {ReactNode} from "react";
import {InfoTable} from "./Home.Styles";
import {Card} from "../../elements/Card/Card";
import {Link} from "react-router-dom";

interface Props {
  title: ReactNode,
  tableHeaders: string[],
  rows?: ReactNode[][],
  href: string,
  hrefName: string
}

export const HomeInfoTable: React.FC<Props> = (props: Props) => {

  const {title, tableHeaders, rows, href, hrefName} = props

  return (
    <InfoTable className='table'>
      {title}
      <Card padding='9px 34px 34px'>
        <div className='table-grid'>
          {
            tableHeaders.map((header, index) => {
              return (
                <div className={`table-grid_title ${index === 2 ? 'last' : ''}`} key={`${index}-${header}`}>
                  {header}
                </div>
              )
            })
          }
        </div>
          <div className='table_divider'/>
          {
            rows && rows.map((row, index) => {
              return (
                <React.Fragment key={`row#${index}`}>
                  <div className='table-grid'>
                    {
                      row.map((el, i) => {
                        return (
                          <React.Fragment key={i}>
                            {el}
                          </React.Fragment>
                        )
                      })
                    }
                  </div>
                  <div className='table_divider'/>
                </React.Fragment>
              )
            })
          }

        <Link to={href} className='table-link'>
          {hrefName}
        </Link>
      </Card>
    </InfoTable>
  )
}
