import React, {ReactNode, useState} from "react";
import {CollapsibleWrapper} from "./CollapsibleCard.Styles";

interface CollapsibleCardProps {
  title: string,
  body?: ReactNode
}

export const CollapsibleCard: React.FC<CollapsibleCardProps> = ({title, body}) => {

  const [isOpen, setIsOpen] = useState(false)

  return (
    <CollapsibleWrapper isOpen={isOpen}>
      <div className='header' onClick={() => setIsOpen(prev => !prev)}>
        <div className='header-text'>
          {title}
        </div>
        <div className='header-btn'>
          {isOpen ? '-' : '+'}
        </div>
      </div>
      {
        isOpen
          ? body
          : null
      }
    </CollapsibleWrapper>
  )
}
