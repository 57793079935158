import styled from "styled-components";

export const CodeSnippetStyles = styled.code`
  font-family: 'Cascadia Code', 'monospace';
  overflow: auto;
  width: 100%;
  
  .codeSnippet {
    &-pre {
      counter-increment: linenumber;
      margin: 0;
      padding:  0 0 0 28px;
      font-family: 'Cascadia Code', 'monospace';
      font-size: 14px;
      
      &:before {
        content: counter(linenumber);
        color: var(--secondary-color);
      }
    }
  }
`