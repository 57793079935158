import styled from "styled-components";

export const SelectWrapper = styled.div`
  position: relative;

  @media only screen and (max-width: 60.6em) {
    width: 100%;
  }
`
export const SelectHeader = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--bg-tetriaty-color);
  height: 40px;
  padding: 0 22px;
  min-width: 160px;
  
  .selectHeader {
    
    &-title {
      font-weight: 600;
      font-size: 14px;
      color: var(--secondary-color);
    }
    
    &-text {
      font-weight: 600;
      font-size: 14px;
      color: var(--blue);
      margin-right: 8px;
    }
  }

  @media only screen and (max-width: 60.6em) {
    width: 100%;
  }
`
export const SelectBody = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  padding: 5px 22px;
  background-color: var(--bg-tetriaty-color);
  cursor: pointer;
  z-index: 20;
  
  &:hover {
    opacity: 0.9;
  }
  
  .selectBody {
    &-item {
      font-weight: 600;
      font-size: 14px;
      color: var(--primary-color);
      padding: 10px 0px;
    }
  }
`