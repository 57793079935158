import React from "react";
import {CheckBoxTitle, CheckBoxWrapper, FormControlCheckbox} from "./CheckBox.Styles";

interface Props {
  title?: string,
  checked?: boolean,
  onChange?: (e: any) => void,
  className?: string
}

export const CheckBox: React.FC<Props> = (props: Props) => {

  const { title, checked, onChange, className } = props

  return (
    <CheckBoxWrapper className={className}>
      <FormControlCheckbox/>
      <CheckBoxTitle>
        {title}
      </CheckBoxTitle>
    </CheckBoxWrapper>

  )
}