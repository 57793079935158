import {useTranslation} from "react-i18next";
import {CheckBox} from "../../../elements/Checkbox/CheckBox";
import {Card} from "../../../elements/Card/Card";
import React from "react";
import {ColorText} from "../../../elements/ColorText/ColorText";
import {Account} from "../../../elements/Account/Account";
import {Link} from "react-router-dom";
import {PATHS} from "../../../const/paths.constants";
import CollapsibleCard from "../../../components/CollapsibleCard";

export const TokenTransactionsTable = ({transactions}: {transactions: any[]}) => {

  const { t } = useTranslation();

  return (
    <CollapsibleCard
      title={`${t('home.table.title.latestTokenTransactions')}`}
      body={
        <div className='flex-rows'>
          <div className='flex-cols checkboxes' style={{gridGap: '20px'}}>
            <CheckBox title={`${t('home.table.title.checkbox.ticktock')}`}/>
            <CheckBox title={`${t('home.table.title.checkbox.elector')}`}/>
          </div>
          <Card padding='16px 20px 29px'>
            {transactions.map((transaction) => {
              return (
                <React.Fragment key={transaction.hash}>
                  <div className='flex-cols between'>
                    <div className='text-blue'>
                      {transaction.hash}
                    </div>
                    <div className='secondaryText'>
                      {t('home.table.content.transfer')}
                    </div>
                  </div>
                  <div className='mainText middle-row'>
                    {transaction.value} TBCC
                  </div>
                  <div className='flex-cols between'>
                    <div className='secondaryText'>
                      {t('home.table.header.route')}
                    </div>
                    <Account account={transaction.route.from} size={18}/>
                    <div className='arrow'>
                      →
                    </div>
                    <Account account={transaction.route.to} size={18}/>
                  </div>
                  <div className='divider'/>
                </React.Fragment>
              )
            })}
            <Link to={PATHS.TOKEN_TRANSACTIONS} className='link'>
              {t('home.table.link.tokenTransactions')}
            </Link>
          </Card>
        </div>
      }
    />
  )
}