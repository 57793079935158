import React, {useState} from 'react';
import { connect } from 'react-redux';
import {
  Container,
  MainContent,
} from '../../elements';
import { useTranslation } from "react-i18next";
import {HomeContainer} from "./Home.Styles";
import { openModal, closeModal } from '../../store/app/actions';
import {MainSearchInput} from "../../elements/MainSearchInput/MainSearchInput";
import {HomeInfoTable} from "./HomeInfoTable";
import {PATHS} from "../../const/paths.constants";
import {testBlocks, testMessagesData, testTransactions} from "./testData";
import {ColorText} from "../../elements/ColorText/ColorText";
import {Account} from "../../elements/Account/Account";
import {CheckBox} from "../../elements/Checkbox/CheckBox";
import {TransactionTable} from "./Tables/TransactionTable";
import {TokenTransactionsTable} from "./Tables/TokenTransactionsTable";
import {MessagesTable} from "./Tables/MessagesTable";
import {BlocksTable} from "./Tables/BlocksTable";

type HomeProps = {
  openModal: (payload: any) => void;
  closeModal: () => void;
};

const Home: React.FC<HomeProps> = (props: HomeProps) => {
  const { openModal, closeModal } = props;
  const { t } = useTranslation();

  const [totalSupply, setTotalSupply] = useState(1084192874)
  const [headBlock, setHeadBlock] = useState(18745356)
  const [transactionCount, setTransactionsCount] = useState(154845114)
  const [messagesCount, setMessagesCount] = useState(24478105)
  const [accountsCount, setAccountsCount] = useState(596095)
  const [validators, setValidators] = useState(117)

  return (
    <MainContent className="content-main home-page">
      <Container>
        <HomeContainer>
          <div className='home-title_wrap'>
            <div className='home-title'>
              {t('home.title')}
            </div>
          </div>
          <MainSearchInput
            name='search'
            type='text'
            placeholder={`${t('home.input.placeholder')}`}
          />
          <div className='home-statistic_grid'>
            <div className='home-statistic_item'>
              <div className='home-statistic_item-title'>
                {t('home.statistic.totalSupply')}
              </div>
              <div className='home-statistic_item-value'>
                {totalSupply.toLocaleString('ru-RU')}
              </div>
            </div>
            <div className='home-statistic_item'>
              <div className='home-statistic_item-title'>
                {t('home.statistic.headBlock')}
              </div>
              <div className='home-statistic_item-value'>
                {headBlock.toLocaleString('ru-RU')}
              </div>
            </div>
            <div className='home-statistic_item'>
              <div className='home-statistic_item-title'>
                {t('home.statistic.transactions')}
              </div>
              <div className='home-statistic_item-value'>
                {transactionCount.toLocaleString('ru-RU')}
              </div>
            </div>
            <div className='home-statistic_item'>
              <div className='home-statistic_item-title'>
                {t('home.statistic.messages')}
              </div>
              <div className='home-statistic_item-value'>
                {messagesCount.toLocaleString('ru-RU')}
              </div>
            </div>
            <div className='home-statistic_item'>
              <div className='home-statistic_item-title'>
                {t('home.statistic.accounts')}
              </div>
              <div className='home-statistic_item-value'>
                {accountsCount.toLocaleString('ru-RU')}
              </div>
            </div>
            <div className='home-statistic_item'>
              <div className='home-statistic_item-title'>
                {t('home.statistic.validators')}
              </div>
              <div className='home-statistic_item-value'>
                {validators.toLocaleString('ru-RU')}
              </div>
            </div>
          </div>
          <div className='home-mainGrid'>
            <HomeInfoTable
              title={
                <div className='home-titleRow'>
                  <div className='home-titleRow_text'>
                    {t('home.table.title.latestTransactions')}
                  </div>
                  <div className='flex-cols' style={{gridGap: '20px'}}>
                    <CheckBox title={`${t('home.table.title.checkbox.ticktock')}`}/>
                    <CheckBox title={`${t('home.table.title.checkbox.elector')}`}/>
                  </div>
                </div>

              }
              tableHeaders={[t('home.table.header.id'),t('home.table.header.account'),t('home.table.header.balanceChange')]}
              href={PATHS.TRANSACTIONS}
              hrefName={`${t('home.table.link.transactions')}`}
              rows={testTransactions.map((transaction) => {
                return [
                  <div className='table-grid__item'>
                    <div className='table-mainText'>{transaction.info.id}</div>
                    <div className='table-secondaryText'>{transaction.info.time}</div>
                  </div>,
                  <div className='table-grid__item'>
                    <Account account={transaction.account} size={28} className='table-mainText'/>
                  </div>,
                  <ColorText className='table-mainText last' value={transaction.change}/>
                ]
              })}
            />
            <HomeInfoTable
              title={
                <div className='home-titleRow'>
                  <div className='home-titleRow_text'>
                    {t('home.table.title.latestTokenTransactions')}
                  </div>
                </div>
              }
              tableHeaders={[t('home.table.header.id'),t('home.table.header.account'),t('home.table.header.balanceChange')]}
              href={PATHS.TOKEN_TRANSACTIONS}
              hrefName={`${t('home.table.link.tokenTransactions')}`}
              rows={testMessagesData.map((transaction) => {
                return [
                  <div className='table-grid__item'>
                    <div className='table-mainText'>{transaction.hash}</div>
                    <div className='table-secondaryText'>{t('home.table.content.transfer')}</div>
                  </div>,
                  <div className='table-grid__item'>
                    <Account account={transaction.route.to} size={16} className='table-mainText'/>
                    <Account account={transaction.route.from} size={16} className='table-mainText'/>
                  </div>,
                  <div className='table-mainText last'>
                    {transaction.value}
                  </div>
                ]
              })}
            />
            <HomeInfoTable
              title={
                <div className='home-titleRow'>
                  <div className='home-titleRow_text'>
                    {t('home.table.title.latestMessages')}
                  </div>
                  <CheckBox title={`${t('home.table.title.checkbox.elector')}`}/>
                </div>
              }
              tableHeaders={[t('home.table.header.hash'),t('home.table.header.route'),t('home.table.header.value')]}
              href={PATHS.MESSAGES}
              hrefName={`${t('home.table.link.messages')}`}
              rows={testMessagesData.map((message) => {
                return [
                  <div className='table-grid__item'>
                    <div className='table-mainText'>{message.hash}</div>
                    <div className='table-secondaryText'>{t('home.table.content.transfer')}</div>
                  </div>,
                  <div className='table-grid__item'>
                    <Account account={message.route.to} size={16} className='table-mainText'/>
                    <Account account={message.route.from} size={16} className='table-mainText'/>
                  </div>,
                  <div className='table-mainText last'>
                    {message.value}
                  </div>
                ]
              })}
            />
            <HomeInfoTable
              title={
                <div className='home-titleRow'>
                  <div className='home-titleRow_text'>
                    {t('home.table.title.latestBlocks')}
                  </div>
                  <CheckBox title={`${t('home.table.title.checkbox.empty')}`}/>
                </div>
              }
              tableHeaders={[t('home.table.header.shardHeight'),t('home.table.header.id'),t('home.table.header.transactions')]}
              href={PATHS.BLOCKS}
              hrefName={`${t('home.table.link.blocks')}`}
              rows={testBlocks.map((block) => {
                return [
                  <div className='table-grid__item'>
                    <div className='table-mainText'>{block.shard}</div>
                    <div className='table-secondaryText'>{block.height}</div>
                  </div>,
                  <div className='table-grid__item'>
                    <div className='table-mainText'>{block.id}</div>
                  </div>,
                  <div className='table-mainText last'>
                    {block.transactions}
                  </div>
                ]
              })}
            />
          </div>
          <div className='home-mobileTable'>
            <TransactionTable transactions={testTransactions}/>
            <TokenTransactionsTable transactions={testMessagesData}/>
            <MessagesTable transactions={testMessagesData}/>
            <BlocksTable blocks={testBlocks}/>
          </div>
        </HomeContainer>
      </Container>
    </MainContent>
  );
};

export default connect(null, { openModal, closeModal })(Home);
