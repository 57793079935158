import styled from "styled-components";

export const CollapsibleWrapper = styled.div<{isOpen?: boolean}>`
  width: 100%;
  margin-bottom: 50px;
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    &-text {
      font-weight: 600;
      font-size: 24px;
      color: var(--primary-color);
    }
    
    &-btn {
      font-size: 24px;
      font-weight: 600;
      color: ${({isOpen}) => isOpen ? 'var(--tetriary-color)' : 'var(--blue)'} ;
    }
  }
  
`