export enum PATHS {
  LANDING = '/landing',
  LOGIN = '/login',
  REGISTRATION = '/registration',
  FORGOT_PASSWORD = '/forgot-password',
  DASHBOARD = '/dashboard',
  CONTRACT = '/contract',
  CONTRACT_ITEM = '/contract/*',
  SHOP = '/shop',
  STATISTICS = '/statistics',
  REFERRALS = '/referrals',
  SETTINGS = '/settings',
  PROFILE = '/profile',

  // tbcc-explorer routes
  HOME = '/',
  BLOCKS = '/block',
  TRANSACTIONS = '/transactions',
  MESSAGES = '/messages',
  ACCOUNTS = '/accoounts',
  TOKENS = '/tokens',
  VALIDATORS = '/validators',
  CONTRACTS = '/contracts',
  NETWORK_CONFIG = '/network',
  TOKEN_TRANSACTIONS = '/token-transactions',
  COLLECTIONS = '/collections'
}
