import {CodeSnippetStyles} from "./CodeSnippet.Styles";

export const CodeSnippet = ({code}: { code: string[] }) => {

  return (
    <CodeSnippetStyles>
      {
        code.map((el, index) => {
          return (
            <pre className='codeSnippet-pre' key={`${index}-${el}`}>
              {el}
            </pre>
          )
        })
      }

    </CodeSnippetStyles>
  )
}