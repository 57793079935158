import styled from 'styled-components';

export const MainContentBlock: any = styled.main`
  width: 100%;
  min-height: calc(100vh - 313px);
  overflow: hidden;
  flex: 1 1 auto;
  font-family: var(--inter);
  background-image: url("/img/main/abstracktDekor.svg"), url("/img/main/abstracktDekor2.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: top right, 0 1000px;
`;
