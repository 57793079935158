import styled from "styled-components";

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 60.6em) {
    width: 100%;
    justify-content: space-between;
    margin-top: 15px;
  }
`
export const DropdownSelector = styled.div`
  position: relative;
  margin-right: 30px;
  
  .dropdown {
     
    &-header {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--input-border-color);
      width: 115px;
      height: 40px;
      
      &__text {
        font-weight: 600;
        font-size: 14px;
        color: var(--secondary-color);
      }
      
      &__number {
        font-weight: 600;
        font-size: 14px;
        color: var(--primary-color);
        margin-right: 6px;
      }
    }
    
    &-body {
      position: absolute;
      top: 40px;
      right: 0;
      background-color: var(--input-border-color);
      
      &__item {
        font-weight: 600;
        font-size: 14px;
        color: var(--primary-color);
        padding: 5px 15px;
        
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
`

export const PageSelector = styled.div<{isDisabled?: boolean}>`
  display: flex;
  align-items: center;

  .pagination {

    &-text {
      font-weight: 600;
      font-size: 14px;
      color: var(--secondary-color);
      margin-right: 15px;
    }

    &-btn {
      width: 40px;
      height: 40px;
      display: flex;  
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 14px;
      background-color: var(--input-border-color);
      
      &_first {
        margin-right: 5px;
      }
      
      &--disbled {
        color: var(--secondary-color);
      }

      &--active {
        color: var(--primary-color);
      }
    }
  }
`