import styled from 'styled-components';

export const TitleBlock: any = styled.h1`
  font-weight: 600;
  font-size: 36px;
  line-height: 56px;
  color: var(--primary-color);


  //@media (max-width: 768px) {
  //  margin: 0 0 25px;
  //  font-size: 32px;
  //  line-height: 32px;
  //}
`;
