import { connect } from 'react-redux';
import {NavbarWrapper} from "./Navbar.styles";
import {Link, useLocation} from "react-router-dom";
import {PATHS} from "../../const/paths.constants";
import {useTranslation} from "react-i18next";
import {useTheme} from "../../theme/useTheme";
import {Theme} from "../../theme/ThemeContext";
import {LanguageSelector} from "../../elements";
import {MainSearchInput} from "../../elements/MainSearchInput/MainSearchInput";
import React from 'react';

const Navbar = () => {

  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { theme, toggleTheme } = useTheme()

  return (
    <NavbarWrapper className='navbar' isTransparent={pathname === '/'}>
      <div className='navbar-wrap'>
        <Link to={PATHS.HOME}>
          <div className='navbar-logo'>
            <img src={theme === Theme.DARK ? '/img/main/textLogo.svg' : '/img/main/textLogoLight.svg'} alt='tbcc-logo'/>
          </div>
        </Link>
        <div className='navbar-itemsWrapper'>
          <Link to={PATHS.BLOCKS} className='navbar-item'>
            {t('navBar.blocks')}
          </Link>
          <Link to={PATHS.TRANSACTIONS} className='navbar-item'>
            {t('navBar.transactions')}
          </Link>
          <Link to={PATHS.MESSAGES} className='navbar-item'>
            {t('navBar.messages')}
          </Link>
          <Link to={PATHS.ACCOUNTS} className='navbar-item'>
            {t('navBar.accounts')}
          </Link>
          <Link to={PATHS.TOKENS} className='navbar-item'>
            {t('navBar.tokens')}
          </Link>
          <Link to={PATHS.VALIDATORS} className='navbar-item'>
            {t('navBar.validators')}
          </Link>
          <Link to={PATHS.CONTRACTS} className='navbar-item'>
            {t('navBar.contracts')}
          </Link>
          <Link to={PATHS.NETWORK_CONFIG} className='navbar-item'>
            {t('navBar.networkConfig')}
          </Link>
        </div>
        <div className='navbar-theme-switcher' onClick={toggleTheme}>
          <img src={theme === Theme.DARK ? '/img/main/switchLight.svg' : '/img/main/switchDark.svg'} alt='theme-switcher'/>
        </div>
        <div>
          <LanguageSelector/>
        </div>
      </div>
      {
        pathname === '/'
          ? null
          :
          <React.Fragment>
            <div className='navbar-divider'/>
            <MainSearchInput
              name='search'
              type='text'
              placeholder={`${t('home.input.placeholder')}`}
              inNavbar
            />
          </React.Fragment>
      }
    </NavbarWrapper>
  )
}

export default connect()(Navbar);