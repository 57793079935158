import React from "react";
import {AccountStyles} from "./Account.Styles";

export const Account = React.memo(({account, size, className}: {account: string, size: number, className?: string}) => {
  return (
    <AccountStyles size={size}>
      <img
        className='image'
        src={`/img/main/account${Math.floor(Math.random() * 3) + 1}.svg`}/>
      <div className={className}>
        {`${account.substring(0, 5)}...${account.substring(account.length - 3)}`}
      </div>
    </AccountStyles>
  )
})
