import styled from "styled-components";

export const FormControlCheckbox = styled.input.attrs({type: 'checkbox'})`
 appearance: none;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  display: inline-block;
  height: 16px;
  width: 16px;
  vertical-align: middle;
  transition: background-color 0.2s ease-in-out;
  border: 1px solid var(--tetriary-color);
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: none;
  margin-right: 7px;

  &:after {
    content: '';
    position: absolute;
    border-bottom: 2px solid;
    border-left: 2px solid;
    border-color: transparent;
    top: 30%;
    left: 0;
    right: 0;
    width: 50%;
    height: 25%;
    margin: auto;
    transform: rotate(-50deg);
    transition: border-color 0.2s ease-in-out;
  }

  &:checked {
    background-color: var(--blue);
    &:after {
      border-color: white;
    }
  }

  &:disabled {
    cursor: default;
    opacity: 0.6;
  }
`
export const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
`
export const CheckBoxTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: var(--primary-color);
`
