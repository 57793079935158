import styled from "styled-components";

export const PlaceHolderStyles = styled.div`
  
  .placeholder {
    width: 100%;
    height: calc(100vh - 400px);
    display: flex;
    align-items: center;
    &-title {
      font-size: 36px;
      color: var(--primary-color);
      font-weight: 500;
    }
  }
`