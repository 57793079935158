import React from 'react';
import {Container, MainContent} from "../../elements";
import {PlaceHolderStyles} from "./PlaceHolder.Styles";

const PlaceHolder = ({name}: {name: string}) => {
  return (
    <MainContent>
      <Container>
        <PlaceHolderStyles>
          <div className='placeholder'>
            <div className='placeholder-title'>
              {name} coming soon...
            </div>
          </div>
        </PlaceHolderStyles>
      </Container>
    </MainContent>
  );
};

export default PlaceHolder;